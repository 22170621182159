<template>
  <transition name="fade-in-up" mode="out-in">
    <router-view :key="$route.fullPath"></router-view>
    <!-- https://stackoverflow.com/questions/58560336/vue-router-push-with-same-route-but-different-parameters-how-to-trigger-trans -->
  </transition>
</template>

<script>
export default {
  components: {},
};
</script>
